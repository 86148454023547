import { activityAPIs, siteAPIs } from '@root/services';
import { isSiteDisable } from '@root/utils';
import i18next from 'i18next';

export const checkUnlicensedSite = (siteId, siteList) => {
  let check = true;
  if (siteId === 'ALL') {
    check = false;
    return check;
  }
  if (siteId) {
    const site = siteList.find((i) => i.siteId === siteId);
    check = isSiteDisable(site);
  }
  return check;
};

export const getFirstLicenseSite = (siteList) => {
  const firstLicenseSite = siteList?.find((i) => !isSiteDisable(i));
  if (firstLicenseSite) {
    const options = [
      {
        ...firstLicenseSite,
        label: firstLicenseSite.organizationName
          ? `${firstLicenseSite.organizationName} ${firstLicenseSite.name}`
          : `${i18next.t('activity.notOrgName')}  ${firstLicenseSite.name}`,
        value: firstLicenseSite.siteId,
        organizationId: firstLicenseSite.organizationId,
      },
    ];

    return options;
  }

  return null;
};

const groupUserIdsBySite = (users, from) => {
  const result = users.reduce((acc, user) => {
    const { accountId, siteId } = user;
    if (!acc[siteId]) {
      acc[siteId] = { siteId, userIds: [], from };
    }
    if (!acc[siteId].userIds.includes(accountId)) {
      acc[siteId].userIds.push(accountId);
    }
    return acc;
  }, {});

  return Object.values(result).filter(
    (item, index, self) =>
      self.findIndex(({ siteId }) => siteId === item.siteId) === index,
  );
};
export const getAtlassianUserInformationByUserActivityData = async ({
  usersByStatus,
  from,
}) => {
  let users = [];
  usersByStatus?.forEach(({ activeUsers, inactiveUsers }) => {
    users = [...users, ...activeUsers, ...inactiveUsers];
  });

  const body = groupUserIdsBySite(users, from);
  const userResponse =
    body?.length > 0
      ? await siteAPIs.getUsersMultySiteFromAtlassianByIds(body)
      : undefined;

  return userResponse?.data?.users || [];
};

export const renderUserStatus = (active, deleted) => {
  if (deleted) {
    return i18next.t('activity.userStatusDeleted');
  }
  let status = i18next.t('activity.userStatusActive');
  switch (active) {
    case 'inactive':
      status = i18next.t('activity.userStatusSuspended');
      break;
    default:
      break;
  }
  return status;
};

export const getAllSiteUsers = async ({
  siteSearching,
  selectedStartDate,
  // filters
  activity,
  jira_software: jiraSoftware,
  jira_service_desk: jiraServiceDesk,
  jira_business: jiraBusiness,
  jira_product_discovery: productDiscovery,
  confluence,
  status,
  productAccess,
  // search
  organization,
  site,
  name,
  email,
  addedDate,
  // sort & pagination
  selectedSort: sort,
  size,
  page,
  currentUserId,
}) => {
  const emailOutOfOrg = i18next.t('activity.emailOutOfOrg');
  const notOrgName = i18next.t('activity.orgNotAdd');
  const siteIds = siteSearching
    ? siteSearching.map((item) => item.value).join(',')
    : '';
  const params = {
    from: selectedStartDate,
    siteIds,
    productTypes: '',
    activity,
    jiraSoftware,
    jiraServiceDesk,
    jiraBusiness,
    confluence,
    productDiscovery,
    status,
    productAccess,
    organization,
    site,
    name,
    email,
    addedDate,
    emailOutOfOrg,
    notOrgName,
    sort,
    size,
    page,
    currentUserId,
  };
  const usersByStatusRaw = await activityAPIs.getAllUsersActivityStatistic(
    params,
  );
  const {
    usersInventory,
    totalPage,
    totalEnableRevoke,
    totalEnableSuspendOrDelete,
  } = usersByStatusRaw.data.raw;
  return {
    usersInventory,
    totalPage,
    totalEnableRevoke,
    totalEnableSuspendOrDelete,
  };
};

export const renderUserStatusColor = (active, deleted) => {
  if (deleted) {
    return 'red';
  }
  let status = 'green';
  switch (active) {
    case 'inactive':
      status = '';
      break;
    default:
      break;
  }
  return status;
};

export const renderProductStatusColor = (dateProduct, selectedStartDate) => {
  let status = 'green';
  const isDate = new Date(dateProduct) >= new Date(selectedStartDate);
  switch (isDate) {
    case false:
      status = '';
      break;
    default:
      break;
  }
  return status;
};

export const renderProductAccess = (active) => {
  let status = i18next.t('activity.noProductAccess');
  switch (active) {
    case true:
      status = i18next.t('activity.hasProductAccess');
      break;
    default:
      break;
  }
  return status;
};

export const USER_INVENTORY_PRODUCT_CELL_SETTINGS = {
  jira_software: {
    name: 'Jira Software',
    minWidth: 100,
    width: 10.5,
  },
  jira_service_desk: {
    name: 'Jira Service Management',
    minWidth: 190,
  },
  jira_business: {
    name: 'Jira Work Management',
    minWidth: 165,
    width: 13.5,
  },
  jira_product_discovery: {
    name: 'Jira Product Discovery',
    minWidth: 165,
    width: 13.5,
  },
  confluence: {
    name: 'Confluence',
    minWidth: 100,
    width: 11,
  },
};
